
@media (max-width: 500px) {
  .hiddenMdDown {
    display: none;
  }

  .fixWidthXSS {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50px;
    white-space: nowrap;
  }

  .fixWidth {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;
  }
}
