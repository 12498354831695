
.loginForm {
  margin-top: 40px;
}

.errorText {
  color: red;
}

.right {
  text-align: right;
}